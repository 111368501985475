import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85')
];

export const server_loads = [0,2,8,7];

export const dictionary = {
		"/": [20],
		"/error": [76],
		"/explore": [~77],
		"/explore/[species]/[valueChain]/[geography]": [~78,[],[19]],
		"/home": [~79],
		"/profile": [80],
		"/read": [81],
		"/read/templates/[templateId]/latest": [~83],
		"/read/[articleId]": [~82],
		"/tables": [84],
		"/unauthorized": [85],
		"/[context]": [21,[2]],
		"/[context]/analysis": [~23,[2,3]],
		"/[context]/analysis/pelagics/analysisupdate/[...articleId]": [~25,[2,3]],
		"/[context]/analysis/preview/[articleId]": [26,[2,3]],
		"/[context]/analysis/salmonids/analysisupdate/[...articleId]": [~27,[2,3,4]],
		"/[context]/analysis/salmonids/monthlyupdate": [28,[2,3,4]],
		"/[context]/analysis/salmonids/monthlyupdate/marketupdates/[...articleId]": [~29,[2,3,4]],
		"/[context]/analysis/salmonids/monthlyupdate/productionupdates/[...articleId]": [~30,[2,3,4]],
		"/[context]/analysis/salmonids/weeklyupdate/[...articleId]": [~31,[2,3,4]],
		"/[context]/analysis/shrimp/analysisupdate/[...articleId]": [~32,[2,3,5]],
		"/[context]/analysis/shrimp/monthlyupdate": [33,[2,3,5]],
		"/[context]/analysis/shrimp/monthlyupdate/marketupdates/[...articleId]": [~34,[2,3,5]],
		"/[context]/analysis/shrimp/monthlyupdate/productionupdates/[...articleId]": [~35,[2,3,5]],
		"/[context]/analysis/shrimp/monthlyupdate/tradeupdates/[...articleId]": [~36,[2,3,5]],
		"/[context]/analysis/shrimp/weeklyupdate/[...articleId]": [~37,[2,3,5]],
		"/[context]/analysis/templates/[templateId]/latest": [~38,[2,3]],
		"/[context]/analysis/[group]/[category]/[...articleId]": [~24,[2,3]],
		"/[context]/biomass-demo": [39,[2]],
		"/[context]/feedback": [~40,[2]],
		"/[context]/industry/analysis": [41,[2,6]],
		"/[context]/industry/analysis/sources-and-methodology": [43,[2,6]],
		"/[context]/industry/analysis/[group]/[category]/[...articleId]": [~42,[2,6]],
		"/[context]/industry/companies/exporters": [~45,[2,6]],
		"/[context]/industry/companies/harvest-stations": [~46,[2,6]],
		"/[context]/industry/companies/on-growing-farmers": [47,[2,6,8]],
		"/[context]/industry/companies/smolt-producers": [~48,[2,6]],
		"/[context]/industry/companies/(company)/[...slug]": [~44,[2,6,7]],
		"/[context]/industry/performance": [49,[2,6,9]],
		"/[context]/industry/performance/costs-and-investments": [50,[2,6,9]],
		"/[context]/industry/performance/costs-and-investments/capital-expenditure": [51,[2,6,9,10]],
		"/[context]/industry/performance/costs-and-investments/capital-expenditure/[sample]": [~52,[2,6,9,10]],
		"/[context]/industry/performance/costs-and-investments/cost-development": [53,[2,6,9,11]],
		"/[context]/industry/performance/costs-and-investments/cost-development/[unit]": [54,[2,6,9,11]],
		"/[context]/industry/performance/costs-and-investments/cost-development/[unit]/[sample]": [~55,[2,6,9,11]],
		"/[context]/industry/performance/key-figures": [56,[2,6,9]],
		"/[context]/industry/performance/key-figures/balance-sheet": [57,[2,6,9,12]],
		"/[context]/industry/performance/key-figures/balance-sheet/[sample]": [~58,[2,6,9,12]],
		"/[context]/industry/performance/key-figures/income-statement": [59,[2,6,9,13]],
		"/[context]/industry/performance/key-figures/income-statement/[sample]": [~60,[2,6,9,13]],
		"/[context]/industry/performance/key-figures/key-figures": [61,[2,6,9,14]],
		"/[context]/industry/performance/key-figures/key-figures/[sample]": [~62,[2,6,9,14]],
		"/[context]/industry/performance/operating": [63,[2,6,9]],
		"/[context]/industry/performance/operating/ebit": [64,[2,6,9,15]],
		"/[context]/industry/performance/operating/ebit/[unit]": [65,[2,6,9,15]],
		"/[context]/industry/performance/operating/ebit/[unit]/[sample]": [~66,[2,6,9,15]],
		"/[context]/industry/performance/operating/harvest-quantity": [67,[2,6,9,16]],
		"/[context]/industry/performance/operating/harvest-quantity/[sample]": [~68,[2,6,9,16]],
		"/[context]/industry/performance/operating/mab-utilisation": [69,[2,6,9,17]],
		"/[context]/industry/performance/operating/mab-utilisation/[sample]": [~70,[2,6,9,17]],
		"/[context]/resources/abbreviations": [~71,[2,18]],
		"/[context]/resources/definitions": [~72,[2,18]],
		"/[context]/resources/product-categories": [~73,[2,18]],
		"/[context]/resources/weight-conversions": [~74,[2,18]],
		"/[context]/upgrade": [~75,[2]],
		"/[context]/[menu]/[species]/[valueChain]/[geography]": [22,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';